@import '../setting';
body{
  .deployment-info{
    background: #fff1b6;
    padding: 5px;
    border: 1px solid #a78701;
    border-radius: 4px;
    margin-bottom: 10px;
    // display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    span{
      color: #a78701;
      font-size: 12px;
      font-family: $ibmplexSemiBold;
      h6{
        color:#478ddc;
        font-size: 12px;
        display: inline;
      }
    }
    img{
      float: right;
      cursor: pointer;
      color: #ff0000;
      margin-top: 2px;
    }
  }

  .warning-msg{
    background-color: #fffbd8;
    border: 1px solid #ffebd8;
    border-radius: 3px;
    font-size: 12px;
  }
  .Select-menu-outer{
    z-index: 999999!important;
  }
  .mobile-header{
    display: none;
    >div{
      display: none;
    }
  }
  .inline-editable-forms{
    input[type="text"], input[type="number"], textarea{
      background: transparent !important;
    }
  }
  #header{
    border-bottom: 1px solid #d7d7d7 !important;
  }
  // No Results Found UI Element
  .no-records-found{
    text-align: center;
    padding:80px 0px;
    background: #fff;
    margin: 0 15px;
    img{
      width:70px;
    }
    h5{
      color:#000;
      margin-top:5px;
      font-size:20px;
      background: transparent;
      border: none !important;
    }
    p{
      color:#69748c;
      width:40%;
      margin:0 auto;
      font-size:14px;
      letter-spacing: 0.5px;
    }
  }
  .page-not-found {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background: #fafafa;
		text-align: center;
    img{
      width: 250px;
    }
    h1{
			font-family: $ibmplexSemiBold;
			font-size: 70px;
			color: $black;
		}
		h4{
			font-family: $ibmplexMedium;
			font-size: 30px;
			color: $black;
		}
		h2{
			font-family: $ibmplexSemiBold;
			font-size: 46px;
      line-height: 56px;
			color: #C0934F;
      margin-bottom: 25px;
		}
		p{
			font-family: $ibmplexRegular;
			font-size: 22px;
      line-height: 28px;
			color: $black;
      margin-bottom: 40px;
		}
		a{
      text-decoration: underline;
      &.return-home{
        font-size: 24px;
        line-height:28px;
        padding: 10px 40px 12px;
        text-transform: uppercase;
        color: $white !important;
			  background: #3F51B5;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        text-decoration: none;
      }
		}
	}
  // Showing Toast Message UI Element
  .MessageWrapper{
  	transform: translateX(460px);
  	-webkit-transition: all 0.2s ease;
  	transition: all 0.2s ease;
  	position: fixed;
    top:20px;
    right:20px;
    display: table;
    margin: 0 auto;
    z-index: 9999;
  	&.open{
  		transform: translateX(0px);
  	}
  	&.close{
  		transform: translateX(460px);
  	}
  	.Message{
  		margin-bottom: 15px;
  		.info {
  	    background-color: #0074D9;
  	  	color: $white;
  	  }
  		.warning {
  	  	background-color: #F39C12;
  	  }
  	  .error {
  	  	background-color: #FF4136;
  	  }
  	  .success {
  	  	background-color: #2ECC40;
  	  }
  		.Message-icon {
  	  	display: table-cell;
  	  	vertical-align: middle;
  	  	padding: 5px 10px;
  	  	text-align: center;
  	  	background-color: rgba(0, 0, 0, 0.25);
  	    color: $white;
  	  	img{
  			  width:14px;
  		  }
  	  }
  		.Message-body {
  	  	display: table-cell;
  	  	vertical-align: middle;
  		  padding: 5px 10px 4px;
  		 	img{
  				width: 12px;
  				position: absolute;
  				right: 8px;
  				top: 8px;
  				cursor: pointer;
  		 	}
    		>p {
  				font-size: 14px;
  	      margin: 0px;
  	      color: $white;
  	  	}
  	  }
  	}
    .Message.is-hidden {
    	opacity: 0;
    	height: 0;
    	font-size: 0;
    	padding: 0;
    	margin: 0 auto;
    	display: block;
    }
    .Message-button {
    	position: relative;
    	margin: 15px 5px -10px;
    	background-color: rgba(0, 0, 0, 0.25);
    	-webkit-box-shadow: 0 3px rgba(0, 0, 0, 0.4);
    	box-shadow: 0 3px rgba(0, 0, 0, 0.4);
    	border: none;
    	padding: 10px 15px;
    	font-size: 16px;
    	color: $white;
    	outline: none;
    	cursor: pointer;
    	&:hover {
    		background: rgba(0, 0, 0, 0.3);
    	}
    	&:active {
    		background: rgba(0, 0, 0, 0.3);
    		-webkit-box-shadow: 0 0px rgba(0, 0, 0, 0.4);
    		box-shadow: 0 0px rgba(0, 0, 0, 0.4);
    		top: 3px;
    	}
    }
    .Message-close {
    	position: absolute;
    	background-color: rgba(0, 0, 0, 0.3);
    	color: $white;
    	border: none;
    	outline: none;
    	font-size: 20px;
    	right: 5px;
    	top: 5px;
    	opacity: 0;
    	cursor: pointer;
    	&:hover {
    		background-color: rgba(0, 0, 0, 0.5);
    	}
    }
  }
  .global-table-list{
    width: 100%;
    table{
      width: 100%;
      thead{
        tr{
          background: #F6F7F8;
          border: 1px solid #EAEAEA;
          th{
            font-size: 12px;
            font-family: $ibmplexMedium;
            color: #898989;
            padding:10px;
            &:empty{
              display: none;
            }
          }
        }
      }
      tbody{
        tr{
          background: $white;
          border-bottom: 1px solid #EAEAEA;
          &:hover{
            box-shadow: 0 0px 8px rgba(0,0,0,0.5);
            position: relative;
            z-index: 1;
            cursor: pointer;
          }
          &:last-child{
            border-bottom: none;
          }
          td{
            font-size: 14px;
            font-family: $ibmplexRegular;
            color: $black;
            padding:15px 10px;
            &:empty{
              display: none;
            }
          }
        }
      }
    }
  }
  .Select-input{
  	padding-left: 0 !important;
  	margin-left: 0 !important;
  }
  input{
  	padding-left: 3px;
  }
  *,h1,h2,h3,h4,h5,h6,p,ul,li{
    padding: 0;
    margin: 0;
    border: none;
    float: none;
    outline: 0;
    &:hover {
      text-decoration: none;
      outline: none;
    }
    &:focus {
      box-shadow: none;
      outline: none !important;
      text-decoration: none;
    }
    &:active {
      box-shadow: none;
      outline: none !important;
      text-decoration: none;
    }
    ::-moz-focus-inner {
      border: 0;
      outline: none;
    }
  }
  a{
    cursor: pointer;
    &:hover, &:focus{
      text-decoration: none;
    }
  }
  input[type="submit"]{
    cursor: pointer;
  }
  .flex-center{
    justify-content: center;
  }
  .flex-centered {
    display: flex;
    display: -webkit-flex;
    flex-flow: nowrap;
    -webkit-flex-flow: wrap;
    align-items: center;
    &:before,&:after {
      width: 0;
    }
  }
  .flex-not-centered {
    display: flex;
    display: -webkit-flex;
    flex-flow: wrap;
    -webkit-flex-flow: wrap;
    &:before,&:after {
      width: 0;
    }
  }
  .no-data-found{
    padding:90px 0;
    color: #C5C5C5;
    text-align: center;
    img{
      display: block;
      margin:0 auto;
    }
  }
  .tooltip > .tooltip-inner {
    font-size:12px;
    font-family:$ibmplexRegular;
  }
  .error-msg{
    font-size: 12px !important;
    color: red !important;
    padding: 3px !important;
  }
  .react-datepicker__day--outside-month {
    color: #a0a0a0 !important;
  }
  .react-datepicker__header__dropdown{
    background: #eaeaea;
    padding: 3px 0;
  }
  select{
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
  }
  .Select-menu{
    font-size: 12px !important;
  }
  .Select-clear-zone{
    position: absolute !important;
    right: 20px;
    top:6px;
  }
  .pagination{
    li{
      // border: 1px solid #007bff;
      border-collapse: collapse;
      &.active{
        background-color: #3f51b5;
        a{
          color: $color_fff;
        }
      }
      a{
        display: inline-block;
        padding: 4px 10px;
        color: #3f51b5;
      }
    }
  }
  .flag-label{
    display: inline-block;
    background: $color_fff;
    border: 1px solid #e9e9e9;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    padding: 3px 15px 3px 12px;
    border-radius: 4px;
    width: 84px;
    font-size: 12px;
    font-family: $ibmplexRegular;
    img{
      margin-right: 6px;
    }
    &.red{
      color:$color_083;
    }
    &.green{
      color:$color_238;
    }
    &.yellow{
      color:#FFDD5E;
    }
  }
  // select drop down
  .custom-select-block-white{
    .Select-control{
      background: #fff!important;
    }
  }
  .custom-select-block{
    .Select-control{
      border:0;
      border-bottom:1px solid #828282;
      border-radius:0;
      background: transparent;
      .Select-multi-value-wrapper{
        .Select-placeholder{
          font-family:$ibmplexRegular;
          font-size: 14px;
          padding:0;
        }
      }
      .Select-arrow-zone{
        position: absolute;
        right:0;
        top:7px;
        .Select-arrow{

          border-color: #3f51b5 transparent transparent !important;
        }
      }
      .Select-value{
        padding-left: 0!important ;
        font-size: 14px;
        .Select-value-label{
          margin-left:0 !important;
        }
      }
    }
  }
  .change-status-modal{
    input[type="text"], textarea, input[type="number"]{
      color:$color_000;
       height: 18px !important;
      font-size: 14px;
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color:#d2d2d2;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color:#d2d2d2;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color:#d2d2d2;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color:#d2d2d2;
      }
    }
  }
  .Select.is-focused:not(.is-open) > .Select-control{
    border-bottom:1px solid #007eff !important;
    box-shadow: 0 0 0 !important;
  }
  input[type="text"], textarea, input[type="number"]{
    color:$color_000;
    height:36px;
    font-size: 14px;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color:#d2d2d2;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color:#d2d2d2;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color:#d2d2d2;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color:#d2d2d2;
    }
  }
  .apha{
    font-size: 36px
  }
  // h2 tag
  .beta{
    font-size: 28px;
  }
  // h3 tag
  .gama{
    font-size: 20px;
  }
  // h4 tag
  .delta{
    font-size: 18px;
  }
  // p tag
  .para{
    font-size: 16px;
  }
  // p/span tag in para if heading h5
  .para-ui{
    font-size: 14px;
  }
  // p/span tag in para if heading h6
  .caption-ui{
    font-size: 12px;
  }
  // label
  .label{
    font-size: 13px;
    color:#a0a0a0;
    &.checkbox-block{
      cursor: pointer;
    }
  }
  .primary-btn{
    font-size:13px;
    color:#ffffff;
    background: #3f51b5;
    border:1px solid #3f51b5;
    border-radius:3px;
    display: inline-block;
    text-transform: uppercase;
  }
  .loi-btn{
    font-family:$ibmplexRegular;
    color: #2670ca;
    font-size: 14px;
    border: 1px solid #2670ca;
    padding: 3px 10px;
    border-radius: 3px;
    cursor: pointer;
    margin-left: 20px;
    margin-bottom: 10px;
  }
  .success{
    border-radius:3px;
    border:1px solid #5FB760;
    background: #5FB760;
    &:hover{
      background: #5FB760;
    }
  }
  .info{
    border-radius:3px;
    background: #3AB0D3;
    border:1px solid #3AB0D3;
    &:hover{
      background: #3AB0D3;
    }
  }
  .warning{
    border-radius:3px;
    background: #EFAC56;
    border:1px solid #EFAC56;
    &:hover{
      background: #EFAC56;
    }
  }
  .danger{
    border-radius:3px;
    background: #D75452;
    border:1px solid #D75452;
    &:hover{
      background: #D75452;
    }
  }
  .autocomplete-dropdown-container{
     >div{
      border-bottom:1px solid #ddd;
      border-right:1px solid #ddd;
      border-left:1px solid #ddd;
      padding:8px;
      font-size: 12px;
    }
  }
  .wrapword{
    white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap; /*Chrome & Safari */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    white-space: pre-wrap;       /* css-3 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    word-break: break-all;
    white-space: normal;
  }
  .edit-audit-history{
    position: absolute;
    top: 30px;
    right: 60px;
    cursor: pointer;
    font-size: 12px;
    color: #3f51b5;
    font-family: $ibmplexRegular;
    text-transform: uppercase;
  }
  .border-bottom-none{
    border-bottom:0;
  }
  .download-block{
    button{
      background: transparent;
      border: none;
      font-family:$ibmplexRegular;
      font-size:14px;
      color:#3f51b5;
      &:focus, &:hover, &:active, &.active{
        box-shadow: 0 0 0 !important;
        background: transparent!important;
        color: #3f51b5!important;
      }
      span{
        margin-right:2px;
        color: #3f51b5;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 500;
        img{
          width: 18px;
          margin-right: 2px;
          position: relative;
          top: -1px;
        }
      }
      &.dropdown-toggle::after{
        position: relative;
        top: 1px;
        color: #3f51b5;
      }
    }
    .dropdown-menu{
      background:$color_fff;;
      border:1px solid #e9e9e9;
      box-shadow:0 2px 4px 0 rgba(0,0,0,0.20);
      border-radius:3px;
      padding:10px;
      .dropdown-item{
        cursor: pointer;
        padding: 0 0 10px;
        border-bottom: 1px solid #ededed;
        margin-bottom: 10px;
        &:last-child{
          border-bottom: none;
          margin-bottom:0;
          padding-bottom:0;
        }
        img{
          width:12px;
          margin-right:10px;
        }
      }
    }
  }
  .auditlog-btn{
    color: #3f51b5!important;
    font-size: 14px !important;
    position:relative;
    font-family: $ibmplexSemiBold !important;
    text-transform: uppercase;
    top:6px;
    &.position{
      top:14px;
    }
    .audit-img{
      width:18px;
      margin-right: 5px;
      display: inline-block;
    }
  }
  .ml-export-link{
    float: right;
    box-shadow: 0 2px 4px rgba(0,0,0,0.5);
  }
  .add-new, .primary-btn{
    color:$color_fff !important;
    padding: 6px 24px;
    border-radius:3px;
    cursor: pointer;
    font-size: 12px;
    border-color: #3f51b5;
    background-color: #3f51b5;
    &:disabled{
      background-color: #3f51b5;
      opacity: 0.5;
      border:1px solid #3f51b5;
    }
  }
  .invoice-status{
   width:115px;
   padding:4px;
   font-size:12px;
  }
  .default-status-ui{
    background: linear-gradient(to bottom, rgba(215,215,215,1) 0%, rgba(246,246,246,1) 95%, rgba(255,255,255,1) 100%);
    color: #333333;
  }
  .on-lease, .status-1{
    background-image:linear-gradient(90deg, #beed87 0%, #d2e9b6 95%);
    color:#5ec264;
    text-align: center;
  }
  .off-lease, .status-2{
    background-image:linear-gradient(269deg, #ffdfcb 0%, #ffb98d 95%);
    color:#ee7c26;
    text-align: center;
  }
  .stored, .status-3{
    background: linear-gradient(to bottom, rgba(215,215,215,1) 0%, rgba(246,246,246,1) 95%, rgba(255,255,255,1) 100%);
    color: #333333;
    text-align: center;

  }
  .status-16{
    background-image: linear-gradient(269deg,#ffdfcb,#c0a696 95%);
    color: #734e31;
  }
  .status-17{
    background-image: linear-gradient(90deg,#e8b8ce,#ee86d1 95%);
    color: #143417;
  }
  .status-14{
    background-image:linear-gradient(269deg, #a7c4ff 0%, #3a66be 100%);
    color:#244382;
  }
  .status-15{
    background-image:linear-gradient(269deg, #a08ad1 0%, #7450bb 100%);
    color:#250f30;
  }
  .written-off, .status-4{
    background-image:linear-gradient(269deg, #a7c4ff 0%, #729cf1 100%);
    color:#4c79d5;
    text-align: center;
  }

  .Draft, .status-0{
    background: #89BFFF;
    color: #1A3B62;
    border-radius: 4px;
    text-align: center;
  }
  .Draft{
    background: #89BFFF;
    border: 1px solid #459BFF;
    color: #1A3B62;
    border-radius: 4px;
    text-align: center;
  }
  .Cancelled{
    background: #F94E35;
    border: 1px solid #C82D17;
    color:#FFFFFF;
    border-radius: 4px;
    text-align: center;
  }
  .Paid{
    background: #9DEC54;
    border: 1px solid #80B31B;
    color:#588E19;
    border-radius: 4px;
    text-align: center;
  }
  .Sent{
    background: #f5db36;
    border: 1px solid #b79f00;
    color:#7c7237;
    border-radius: 4px;
    text-align: center;
  }
  .Partially-Paid{
    background: #a6d186;
    border: 1px solid #4ead54;
    color:#3f6910;
    border-radius: 4px;
    text-align: center;
  }
  //   common scss
  .reqStar{
    color: #ff0000;
    font-size: 14px;
    position: relative;
    top: 0;
    img{
      position: relative;
      top: -3px;
      left: 1px;
      cursor: pointer;
    }
  }
  .border-bottom-none{
    border-bottom:none!important;
  }
  .margin-bottom-10{
    margin-bottom:10px !important;
  }
  .margin-bottom-0{
    margin-bottom:0 !important;
  }

  .margin-0{
    margin:0 !important;
  }
  .padding-0{
    padding:0;
  }
  .padding-right-0{
    padding-right:0;
  }
  .padding-left-0{
    padding-left:0 !important;
  }
  .margin-bottom-15{
    margin-bottom:15px;
  }
  .padding-left-46{
    padding-left: 46px !important;
  }
  .padding-bottom-15{
    padding-bottom:15px;
  }

  .width-33{
    >div{
      width:33%;
    }
  }
  .width-50{
    >div{
      width:50%;

    }
  }
  .table-block{
    table{
      th, td{
        border:1px solid #ddd;
      }
    }
  }
  .lease_date_note{
    border: 2px solid #909090;
    border-radius: 3px;
    background-color: #f4f8be;
    color: #e4c266;
  }
  .maintenance-bar{
    background-color: #fffbd8;
    border: 1px solid #ffebd8;
    border-radius: 3px;
    position: fixed;
    top: 0;
    z-index: 9999;
    left: 0;
    right: 0;
    font-size: 12px;
    padding: 6px;
    text-align: center;
    p{

    }
    img{
      float: right;
      width: 10px;
      cursor: pointer;
      position: relative;
      top: 4px;
    }
  }
  .commons-tabs{
    margin-bottom:15px;
    .tab-heading-inner-wrap{
      overflow: hidden;
      width: 160%;
      display: flex;
      flex-flow: nowrap;
      flex-shrink: 0;
      text-align: left;
    }
    .nav-link{
      font-family:$ibmplexRegular;
      font-size:$font12;
      color:#626262;
      text-align:center;
      cursor:pointer;
      padding: 0 5px 4px;
      margin-right: 12px;
      text-transform: uppercase;
      &.active{
        background: transparent;
        border-color:transparent;
        font-family:$ibmplexRegular;
        font-size:$font12;
        color:#3f51b5;
        border-bottom: 1px solid #3f51b5;
        &:hover{
          border-bottom:1px solid #3f51b5;
        }
      }
      &.error{
        background: transparent;
        border-color:transparent;
        font-family:$ibmplexRegular;
        font-size:$font12;
        color:red;
        border-bottom: 1px solid red;
        &:hover{
          border-bottom:1px solid red;
        }
      }
      &:hover{
        border-color:transparent;
        padding: 0 5px 4px;
      }
    }
  }
  .ui-tabs{
    border-bottom: 1px solid $borderGrey;
    li{
      margin: -1px 0 0 0;
      a{
        font-size: $font12;
        font-family: $ibmplexBold;
        color: $placeholderGrey !important;
        border-bottom: 1px solid transparent;
        padding: 8px 30px;
        cursor: pointer;
        display: inline-block;

        &.active{
          color: #3f51b5 !important;
          border-bottom: 1px solid #3f51b5;
        }
      }

    }
  }
  .normal-table-layout{
    width: 100%;
    border: 1px solid $borderGrey;
    thead{
      background: #F7F7F7;
      th{
        font-size: $font12;
        font-family: $ibmplexMedium;
        color: $labelGrey;
        padding: 10px;
      }
    }
    tr{
      td{
        padding: 10px;
      }
      &:nth-child(even){
        background: #EDF0F3;
      }
    }
  }
  .report-export-lists{
    .report-export-title{
      margin-bottom: 10px;
      h4{
        font-size: 20px;
        color: #bd10e0;
        font-family: $ibmplexRegular;
      }
      p{
        font-size: 13px;
        color: #838383;
        font-family: $ibmplexRegular;
      }
      .bulk-rpt-cta{
        float: right;
        button{
          padding: 6px 12px;
          border-radius: 4px;
          font-size: 14px;
          color: #fff;
          font-family: $ibmplexRegular;
        }
      }

    }

    .asset-card{
      img{
        float: left;
        margin-right: 15px;
      }
      h4{
        font-size: 16px;
        font-family: $ibmplexMedium;
        color: #000;
      }
    }
  }
  .report-export-section{
    padding: 0 25px;
    background: #fff;
    .export-module-card{
      position: relative;
      z-index: 99;
      box-shadow: 0 2px 4px rgba(0,0,0,0.6);
      overflow: hidden;
      background: #fff;
      margin-bottom: 30px;
      border-radius: 4px;
      &.aircraft-report-card{
        margin-top: 18px;
      }
      &.cust-cashflow-card{
        overflow: visible;
      }
      .rpt-card-header{
        border-bottom: 1px solid #d7d7d7;
        padding:8px 15px;
        h2{
          font-size: 18px;
        }
      }
      .rpt-card-body{
        padding: 15px;
        .sub-mod-title{
          font-size: 16px;
          margin-bottom: 10px;
        }
        .sub-mod-card{
          margin-left: 10px;
        }
        .export-items{
          margin-bottom: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid #d7d7d7;
          &:last-child{
            border-bottom: none;
            margin-bottom: 0;
          }
        }
        .export-label{
          margin-bottom: 10px;
          span{
            font-size: 14px;
            padding-left: 6px;
          }
        }
        .export-avial{
          margin-left: 20px;
          h6{
            font-size: 14px;
            margin-bottom: 0px;
          }
          ul{
            display: inline-block;
            li{
              margin-right: 15px;
              label{
                span{
                  padding-left: 6px;
                  font-size: 12px;
                }
              }
            }
          }
        }
        .filter-avail{
          margin-top: 10px;
          background: #eaeaea;
          margin-left: 20px;
          padding: 10px;
          .Select-control{
            width: 180px;
          }
          ul{
            li{
              label{
                font-size: 12px;
              }
            }
          }
        }
        .filter-avail-custom{
          margin-top: 10px;
          background: #eaeaea;
          margin-left: 20px;
          padding: 10px;
          .Select-control{
            width: 180px;
          }
          ul{
            li{
              label{
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  .error-page{
    text-align: -webkit-center;
    height: 100vh;
    h2{
      font-size: 32px;
      color: #838383;
      font-family: $ibmplexMedium;
    }
    p{
      font-size: 22px;
      color: #838383;
      font-family: $ibmplexRegular;
      margin-top: 40px;
    }
    .error-image{
      display: block;
      margin: 30px 0;
      width: 400px;
    }
    .go-back-button{
      margin-top: 20px;
      padding:10px;
      a{
        color: $color_fff;
      }
    }
  }
  .siq_bR{
    bottom: 10px!important;
    left: 7px!important;
    .zsiq_float{
      .zsiq_cnt{
        left: 70px!important;
        &::after{
          left: -205px!important;
          box-shadow: none!important;
        }
      }
    }
  }
  .zls-sptwndw{
    left: 0!important;
  }
}

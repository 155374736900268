@import '../../../assets/styles/setting';


body{
  .user-detail{
    .react-tel-input{
      .special-label{
        display: none
      }
    }
  }
  .profile-lessor-list{
    .modal-title{
      width: 100%;
    }
    .modal-body{
      .col-md-6{
        padding: 10px;
        input[type="checkbox"]{
          margin-right: 5px;
        }
      }
    }
  }
  .import-asset-con{
    height: 100%;
    .ml-modal-upload{
      margin-top: 5px;
      padding: 40px 20px 40px 20px;
      border: 1px solid #000;
      width: 240px;
      border-style: dashed;
      h5{
        color: #d3d3d3;
        font-size: 12px;
        font-family: $ibmplexRegular;
      }
      img{
        margin-bottom: 5px;
      }
    }
  }
  .profile-skills-list{
    .modal-content{
      width: 700px;
      .modal-body{
        width: 700px;
        .users-contract-list-con{
          font-family: $ibmplexRegular;
          .users-contract-list-con-header{
            padding: 10px 20px;
            color: #8c9ba5;
            background: #f6f6f6;
            border: 1px solid #eaeaea;
            font-size: 12px;
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 10px;
          }


          .users-contract-list{
            background: #fff;
            font-size: 14px;
            padding: 10px 20px;
            border-left: 1px solid #eaeaea;
            border-right: 1px solid #eaeaea;
            border-bottom: 1px solid #eaeaea;
            margin-left: 10px;
            margin-right: 10px;
            a{
              margin-right: 10px;
              display: none;
            }
            &:hover{
              a{
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
  .asset-listing-wrap{
    .no-records-found{
      margin:0;
    }
    .drop-down{
      font-size: 14px !important;
      padding: 6px 12px !important;
    }
    .portfolio-type{
      background-color: #56b4da;
      color: #fff!important;
      padding: 2px 10px;
      font-size: 11px;
      font-weight: 700;
    }
    .add-asset-dd{
      box-shadow: 0px 1px 1px rgba(0,0,0,0.2);
      float: right;
      border-radius: 4px;
      &.show{
        .dropdown-toggle{
          background: transparent;
          color: #000;
          &:focus, &:active, &:hover{
            background: #3f51b5;
            color: #fff;
            border: 1px solid #3f51b5;
            font-size: 14px;
            padding: 6px 12px;
            text-transform: uppercase;
          }
        }
      }
     .dropdown-toggle{
        background: #3f51b5;
        color: #fff;
        border: 1px solid #3f51b5;
        font-size: 13px;
        padding: 5px 10px;
        text-transform: uppercase;

        &:focus, &:active, &:hover{
          box-shadow: 0 0 0;
          background: #3f51b5;
          color: #fff;
          border: 1px solid #3f51b5;
          font-size: 14px;
          padding: 6px 12px;
        }

      }
      .dropdown-menu{
        padding:0;
        .dropdown-item{
          cursor: pointer;
          padding: 8px;
          font-size: 13px;
          border-radius: 2px;
          &:active{
            background: #3f51b5!important;
          }
        }
      }
    }
    h1{
      font-family:$ibmplexRegular;
      font-size:20px;
      color:#000000;
      margin-bottom:20px;
    }
    hr{
      margin:0;
      display:none;
    }
    .tab-left{
      margin-bottom:0;
      li{
        font-family:"Roboto", "Helvetica", "Arial", sans-serif;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.54);
        padding: 0 16px 6px 20px;
        cursor: pointer;
        text-transform: uppercase;
        &.active{
          border-bottom: 2px solid #3f51b5;
          color: #3f51b5!important;
        }
      }
    }
    .tab-right{
      padding-left: 10px;
      background: #fafafa;
      float: right;
      position: relative;
      top: -10px;
      li{
        &.count-block{
          background:#2f8cff;
          border-radius:20px;
          line-height: 12px;
          padding: 1px 5px;
          span{
            font-family:$ibmplexRegular;
            font-size:8px;
            color:$color_fff;
            position: relative;
            top:-1px;
            display: inline-block;
          }
        }
        &.download-block{
        }
          .show{
            .btn-secondary.dropdown-toggle{
              background: none!important;
            }
          }

      }
    }

    .title-block{
      margin-left: -5px;
      margin-bottom: 15px;
      h1{
        @include elementStyles(16px, #bd10e0, normal, normal, $ibmplexSemiBold, null, null);
        margin-bottom: 3px;
      }
      p{
        @include elementStyles(13px, #000000, normal, normal, $ibmplexRegular, null, null);
      }
    }
    .card-wrapper{
      border-radius: 3px;
      box-shadow: 0 0 3px 0 #838383;
      padding-bottom: 5px;
      background: #ffffff;
      padding: 10px 0;
      height: 75vh;
      .flex-not-centered{
        margin: auto;
        .label{
          word-break: break-word;
          color: #838383;
          font-size: 14px;
          font-weight: 600;
        }
        .data{
          font-size: 13px;
          color: #000000;
        }
        h1,h2,h3,h4,h5{
          font-size: 14px;
          margin-bottom: 5px;
          color: #000000;
        }
        p, span, h6, div{
          font-size: 13px;
          color: #838383;
          margin-bottom: 5px;
        }
        ul{
          li{
            list-style: none;
            font-size: 14px;
            color: #838383;
          }
        }
      }
    }
    .tableau-container{
      box-shadow: 0 0 3px 0 #838383;
      border-radius: 3px;
      background: #ffffff;
      iframe{
        border-radius: 3px;
        width: 100% !important;
        height: 75vh !important;
      }
    }
  }
  .variable-rent-header{
    background-color: #eaeaea;
    padding: 7px;
  }
  .variable-rent-modal{
    .vR-container{
      h6{
        font-size: 15px !important;
      }
      .MuiTableCell-root{
        font-size: 0.805rem !important;
      }
    }
  }
  .asset-list-filter-sort{
    .add-filter-type{
      background: #fff;
      padding: 5px 1px 3px;
      margin: 0;
      height: 42px;
      border: 1px solid #EAEAEA;
      &.asset-list-fixed{
        position: fixed;
      }
      .cash-list-filter{
        .year-blk{
          width:200px;
        }
        .reserve-blk{
          width:250px;
        }
      }
      .add-filter{
        position: relative;
        top: 1.3px;
        .close-block{
          width:10px;
          position: relative;
          top:-1px;
          margin-right: 4px;
        }
        .plus-icon{
          width:10px;
          margin-right:4px;
        }
        p{
          font-family: $ibmplexRegular;
          font-size: 14px;
          cursor: pointer;
          display: inline-block;
          padding: 4px 17px;
          border-radius: 20px;
          border: 1px dashed;
          background: $color_fff;
          float: left;
          margin: 0 20px 10px 0
        }
        ul.applied-filter{
          .close-block{
            width: 8px;
            position: relative;
            top: 0;
          }
          li{
            float: left;
            padding: 2px 30px 2px 16px;
            margin-right: 5px;
            position: relative;
            margin-bottom: 4px;
            background:#e6e9f2;
            border:1px solid #c4cde3;
            border-radius:16px;
            font-family:$ibmplexRegular;
            font-size:12px;
            color:#0f202c;
            letter-spacing:0.22px;
            text-align:left;
            top:3px;
            span{
              cursor: pointer;
              position: absolute;
              right: 0;
              top: -3px;
              color: #0f202c;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              text-align: center;
              font-size: 16px;
            }
          }
        }
        ul.filter-list{
          max-height:400px;
          overflow: auto;
          &::-webkit-scrollbar{
            display: none;
          }
          border: 1px solid #dfe3e6;
          border-top:1px solid #dfe3e6;
          background: #fff;
          width: 400px;
          position: absolute;
          top: 43px;
          z-index: 2;
          box-shadow: 0px 1px 1px rgba(0,0,0,0.2);
          li{
            cursor: pointer;
            font-family: $ibmplexRegular;
            font-size: 12px;
            padding:10px 15px;
            border-bottom:1px solid #dfe3e6;
            transition: all 0.5s ease;
            &:last-child{
              border-bottom:none;
            }
            &:hover{
              background: #f5f5f5;
            }
          }
        }
        .match-block{
          background: $color_fff;
          width:320px;
          box-shadow: 0 15px 34px -10px rgba(0,0,0,.2);
          position: absolute;
          z-index: 2;
          top: 0;
          .match-header{
            background: #3f51b5;
            padding:10px 15px;
            h4{
              font-size: 16px;
              font-family: $ibmplexRegular;
              color:$color_fff;
            }
            span{
              cursor: pointer;
              float:right;
              color:$color_fff;
            }
          }
          .match-body{
            padding: 20px 15px 0;
            >div{
              width: 94%;
              float: left;
            }
            label{
              display: block;
              font-size: 14px;
              font-family: $ibmplexRegular;
              margin-bottom:0;
              color:#626262;
            }
            input[type="text"], input[type="number"]{
              border-bottom:1px solid #838383;
              height:30px;
            }
            .Select-control{
              background:#ffffff;
              border:none;
              border-bottom:1px solid #dbdbdb;
              border-radius:3px;
              height:28px;
              width: 80%;
              font-family:$ibmplexRegular;
              border-radius:0;
              .Select-multi-value-wrapper{
                font-size: 12px;
              }
              .Select-placeholderl{
                font-family:$ibmplexRegular;
                font-size:12px;
                color:#cacaca;
                text-align:left;
                font-family:$ibmplexRegular;
              }
              .Select-value-label{
                font-family:$ibmplexRegular;
                font-size:12px;
                color:#000;
                text-align:left;
                font-family:$ibmplexRegular;
              }
            }
            .react-datepicker-wrapper{
              margin-bottom: 12px;
              input{
                font-size: 12px;
                color: #000;
                &:focus{
                  border-bottom:1px solid #007bff;
                }
              }
            }
            .Select--multi .Select-value {
              background-color: #ebf5ff;
              background-color: rgba(0, 126, 255, 0.08);
              border-radius: 2px;
              border: 1px solid #368FFC;
              color: #007eff;
              display: inline-block;
              font-size: 0.9em;
              line-height: 1.4;
              margin-left: 5px;
              margin-top: 5px;
              vertical-align: top;
              .Select-value-icon{
                cursor: pointer;
                border-bottom-left-radius: 2px;
                border-top-left-radius: 2px;
                border-right: 1px solid #007eff;
                padding: 1px 5px 3px;
              }
              .Select-value-label{
                color: #007eff;
              }
            }
          }
          .match-footer{
            text-align: right;
            padding:15px;
            padding: 28px 15px 15px;
            input[type="submit"]{
              text-align: right;
              font-size: 12px;
              padding:6px 15px;
            }
          }
        }
      }
    }
    .filter-block{
      background: #fafafa;
      border: 1px solid #dfe3e6;
      margin: 0;
      overflow: visible;
      padding: 4px 0;
      .img-block{
        padding:0;
        .select-filter-block{
          cursor: pointer;
          padding:6px 6px 9px;
          margin-right: 14px;
          background-image:linear-gradient(-270deg, #6f83ff 0%, #2c92ff 100%);
          display: inline-block;
          &.expanded{
            background: transparent;
          }
        }
        .Select{
          margin-left: 4px;
        }
        h6{
          padding: 0;
          font-family: $ibmplexRegular;
        }
        .filter-option{
          margin-left:37px;
          font-family: $ibmplexRegular;
        }
        .msn-search-block{
          .Select--single{
            width: 82px;
            .Select-control{
              width: 82px;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
          input[type="text"]{
            width: 100px;
            background: #fff;
            border: 1px solid #dbdbdb;
            border-radius: 3px;
            height: 36px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left:0;
            padding: 4px;
            font-family:$ibmplexRegular;
            font-size:12px;
          }
        }
      }
      h6{
        padding:10px 6px 10px 0;
        font-family:$ibmplexSemiBold;
        font-size:13px;
        color: #6d9bb9;
        margin-bottom:0;
        .asc-sort,  .desc-sort{
          font-size: 7px;
          color:#dfdfdf;
          cursor: pointer;
        }
        .asc-sort{
          position: relative;
          right: -8.5px;
          top: -4px;
          font-size: 7px;
        }
        .asc-sort-safari{
          position: relative;
          right: -10.5px;
          top: -4px;
          font-size: 7px;
        }
        .desc-sort{
          position: relative;
          top: 3px;
        }
        span{
          cursor: pointer;
          &.cursor-change{
            cursor: default;
          }
        }
      }
      .filter-option{
        margin-bottom: 9px;
        background:$color_fff;
        border:1px solid #dbdbdb;
        border-radius:3px;
        font-family:$ibmplexRegular;
        font-size:12px;
        color:#cacaca;
        padding: 4px 7px ;
        height:28px;
        width:103px;
      }
      .Select-control{
        background:#ffffff;
        border:1px solid #dbdbdb;
        border-radius:3px;
        height:28px;
        width: 80%;
        font-family:$ibmplexRegular;
        .Select-multi-value-wrapper{
          font-size: 12px;
        }
        .Select-placeholder, .Select-value-label{
          font-family:$ibmplexRegular;
          font-size:12px;
          color:#cacaca;
          text-align:left;
          font-family:$ibmplexRegular;
        }
      }
    }
  }
  .asset-list{
    display: block;
    cursor: default;
    border:1px solid #dfe3e6;
    border-top:none;
    padding:8px;
    &:nth-child(even){
      background: #ebeff6;
    }
    &:nth-child(odd){
      background: #f5f7fa;
    }
    .asset-list-cta{
      display: none;
      li{
        padding-left: 3px;
        &:first-child{
          padding-left: 0;
        }
      }
    }
    .asset-initial-icon{
      img{
        width: 20px;
        margin-right: 3px;
      }
    }
    &:hover{
      background: #f5f5f5;
      .asset-list-cta{
        display: block;
      }
      .asset-initial-icon{
        display : none;
      }
    }
    .msn-block{
      a{
        h4{
          color: #000;
        }
      }
      img{
        margin-right:10px;
      }
      span{
        font-family:$ibmplexRegular;
        font-size:11px;
        color:$color_000;
      }
    }
    h4{
      font-family:$ibmplexSemiBold;
      color:$color_000;
    }
    .asset-logo{
      max-width: 75px;
    }
    .in-due{
      h6{
        .pretty-number-override-css {
          color: rgb(255, 0, 0)!important;

        }
      }
    }
    h6{
      font-family:$ibmplexRegular;
      color:$color_000;
      line-height:20px;
      b{
        font-family:$ibmplexSemiBold;
      }

      span{
        text-transform: uppercase;
        font-family:$ibmplexSemiBold;
        border-radius: 4px;
        font-size: 10px;
        display: inline-block;
        text-align: center;
        line-height: 12px;
        &.overdue-text{
          color:rgb(255, 0, 0);
          text-transform: inherit;
          }
      }
    }

  }


/** log listing css ***/
  .block-title{
      @include elementStyles(16px, #bd10e0, normal, normal, $ibmplexRegular, null, null);
  }
  .add-enquiry-block{
    margin-bottom: 15px;
    .switch{
      width:40px;
      height: 14px;
      background: #D8D8D8;
    }
    .switch-toggle{
      width: 16px;
      height:16px;
      top:-2px;
    }
    .switch.on{
      background: #7ED321;
    }
    .switch-text{
      font-size:10px;
      color:#D6D6D6;
      margin-left: 6px;
    }
    a{
      background: #368FFC;
      font-size: 12px;
      color:$color_fff !important;
      padding:7px 20px;
      border-radius: 3px;
    }
  }
  .checkbox-compare{
    margin-right: 4px;
  }
  .log-heading-block{
    h6{
      margin-left: 60px;
    }
  }
  .pagination{
    margin-top: 15px;
    text-align: center;
    justify-content: center;
    li{
      border: 1px solid #dfe3e6;
      border-left:none;
      background: $color_fff;
      &:first-child{
        border-left:1px solid #dfe3e6;
      }
      &.active{
        border: 1px solid #3f51b5;
      }
      a{
        font-family: $ibmplexRegular;
        font-size: 12px;
      }
    }
  }
}


//user management scss
.user-profile{
  table{
    background: #ffffff;
    border-collapse:collapse;
    td{
      word-wrap: break-word;
      white-space: normal;
      width: 100%;
      padding: 10px;
      min-width: 100px;
      font-size: 14px;
      font-family: $ibmplexRegular;
      font-size:14px;
      color: #000;
      &:first-child{
        width:60px;
      }
    }
    thead{
      tr{
        &:first-child{
          background: rgba(255,255,255,0.07);
          background: -moz-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(237,237,237,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(0,0,0,0.07)), color-stop(100%, rgba(0,0,0,0)));
          background: -webkit-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          background: -o-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          background: -ms-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          background: linear-gradient(to bottom, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
          td{
            font-family:$ibmplexRegular;
            font-size:12px;
            color: #8c9ba5;
            border: 1px solid #d3d3d3;
          }
        }
      }
    }
    tbody{
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
      display: block;
      tr{
        td{
          img{
            width: 40px;
          }
        }
      }
    }
    tbody, thead{
      width: 100%;
      tr{
        table-layout: fixed;
        display:table;
        width:100%;
      }
    }
  }
}

// user profile scss


@media (max-width: 768px) {
  .asset-listing-wrap{
    padding-top:15px;
    .mobile-asset-list{
      width:100%;
      overflow:auto;
      .add-filter-type{
        display:none;
      }
      .asset-list-filter-sort{
        width:240%;

      }
      .asset-list{
        width:240%;

        h4{
          font-size:14px;

        }
        h6{
          font-size: 12px;
          &.gama{
            font-size:12px;
          }
          &.theta{
            font-size:12px;
          }
          span{
            display: inline-block;
            padding: 3px 15px;
          }
          .pretty-number-override-css {
            font-size: 12px;
            font-family:$ibmplexRegular;
          }
        }
      }
    }
  }

//user management scss
  table{
    background: #ffffff;
    border-collapse:collapse;
    td{
      word-wrap: break-word;
      white-space: normal;
      width: 100%;
      padding: 10px;
      min-width: 100px;
      font-size: 14px;
      font-family: $ibmplexRegular;
      font-size:14px;
      color: #000;
      &:first-child{
        width:60px;
      }
    }
    thead{
      tr{
        &:first-child{
          background: rgba(255,255,255,0.07);
          background: -moz-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(237,237,237,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(0,0,0,0.07)), color-stop(100%, rgba(0,0,0,0)));
          background: -webkit-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          background: -o-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          background: -ms-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          background: linear-gradient(to bottom, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
          td{
            font-family:$ibmplexRegular;
            font-size:12px;
            color: #8c9ba5;
            border: 1px solid #d3d3d3;
          }
        }
      }
    }
    tbody{
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
      display: block;
      tr{
        td{
          img{
            width: 40px;
          }
        }
      }
    }
    tbody, thead{
      width: 100%;
      tr{
        table-layout: fixed;
        display:table;
        width:100%;
      }
    }
  }
// user profile scss


}




/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .asset-list{
    .filter-block{
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
    }
    >.flex-centered{
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
    }
    h4{
      font-size:14px;

    }
    h6{
      font-size: 12px;
      &.gama{
        font-size:12px;
      }
      &.theta{
        font-size:12px;
      }
      span{
        display: inline-block;
        padding: 3px 15px;
      }
      .pretty-number-override-css {
        font-size: 12px;
        font-family:$ibmplexRegular;
      }
    }
  }
}




// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  body{
    .asset-listing-wrap{
      padding: 15px;
      margin-left: 30px !important;
      .asset-list{
        h6{
          span{
            padding: 4px 0 2px;
            width: 100px;

          }
        }
      }
    }
  }
}


// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .asset-listing-wrap{
    padding:15px;
  .asset-list{
    h4{
      font-size:17px;
    }
    h6{
      span{
        padding: 6px;
        width: 100px;
        font-family: Roboto, Helvetica, Arial, sans-serif;
      }

      &.gama{
        font-size:16px;
      }
      &.theta{
        font-size:14px;
      }
      .pretty-number-override-css {
        font-size: 14px;
        font-family:$ibmplexRegular;
      }
    }
  }
}
}
//user management scss
  .users-list{
    background: #ffffff;
    width: 95%;
    margin-left: 60px;
    thead{
      border-bottom: 1px solid #dfe3e6;
      background:#EFEFEF;
    }
    td{
      padding: 10px;
      min-width: 100px;
      font-size: 14px;
      font-family: $ibmplexRegular;
      color: #000;
      a{
        margin-right: 20px;
        &:last-child{
          color: red;
          &:hover{
            color: red;
          }
        }
      }
    }
    thead{
      tr{
        border: 1px solid #ddd;
        &:first-child{
          background:#F0F2F3;
          // background: rgba(255,255,255,0.07);
          // background: -moz-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          // background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(237,237,237,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(0,0,0,0.07)), color-stop(100%, rgba(0,0,0,0)));
          // background: -webkit-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          // background: -o-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          // background: -ms-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          // background: linear-gradient(to bottom, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
          td{
            font-family:$ibmplexBold;
            font-size:12px;
            color: #8c9ba5;
            &.td-userList{
             width: 30%;
            }
            h6{
              padding:6px 6px 9px 0;
              font-family:$ibmplexBold;
              font-size:12px;
              color:#8c9ba5;
              margin-bottom:0;
              .asc-sort,  .desc-sort{
                font-size: 7px;
                color:#dfdfdf;
                cursor: pointer;
              }
              .asc-sort{
                position: relative;
                right: -8.5px;
                top: -4px;
                font-size: 7px;
              }
              .asc-sort-safari{
                position: relative;
                right: -10.5px;
                top: -4px;
                font-size: 7px;
              }
              .desc-sort{
                position: relative;
                top: 3px;
              }
              span{
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    tbody{
      width: 100%;
      tr{
        border: 1px solid #e7ecef;
        cursor: pointer;
        td{
          img{
            width: 40px;
            height: 40px;
            margin-right: 5px;
            border: 0px;
            border-radius: 50%;
          }
          &:last-child{
           // display: block
            color: #007bff;
          }
        }
        &:hover{
          background:#F5F5F5;
          td{
            &:last-child{
             // display: block;
              color: #007bff;
            }
          }
        }
        &.tr-deactivate{
          cursor:default;
          background:white;
          td{
            opacity:0.5;
            &:last-child{
              opacity: 1;
              a{
                &.deactivate{
                  cursor:default;
                  opacity: 0.5;
                }
                &.reactivate{
                  cursor: pointer;;
                  opacity: 0;
                }
              }
            }
          }
          &:hover{
            background:white;
            td{
              &:last-child{
                a{
                  &.reactivate{
                    opacity: 1;
                    text-decoration:underline;
                  }
                }
              }
            }
          }
        }
        &.tr-no-hover{
          cursor:default;
          &:hover{
            background:white;
          td{
            &:last-child{
            // display: block;
             cursor: pointer;
             color: #007bff;
            }
          }
        }
      }
      }
    }
  }

 //modal list scss
 .modal-list-user{
   padding-bottom:10px;
   padding-top: 10px;
   border-bottom:1px solid #d3d3d3;
   &:hover{
    background:#E7E7E7;
   }
 }
// add user css
.editSideBar{
  .row{
    margin-left: 10px;
    margin-right: 10px;
  }
  .tech-item{
    input{
      height: 25px;
    }
    .Select{
      height: 25px;

    }
  }
  .tech-item, .tech-sum-item{
    border-bottom: 1px solid #d7d7d7;
    input{
      display: block;
      width: 100%;
    }
    input[type = 'file']{
      font-size: 11px;
    }
    .Select{
      .Select-control{
        border-bottom: none;
        &:hover{
          border-bottom: none!important;
        }
      }
    }
  }
}
// user profile scss


  .user-profile-blk{
    margin: 20px 0 0 0 !important;
    .gama{
      margin-left: 60px;
    }
    .title-block{
      margin-left: -5px;
      margin-bottom: 15px;
      h1{
        @include elementStyles(16px, #bd10e0, normal, normal, $ibmplexRegular, null, null);
        margin-bottom: 3px;
      }
      p{
        @include elementStyles(13px, #838383, normal, normal, $ibmplexRegular, null, null);
      }
    }
    .user-skill-list-item{
      float: left;
      padding: 2px 16px 2px 16px;
      margin-right: 5px;
      position: relative;
      margin-bottom: 4px;
      background: #e6e9f2;
      border: 1px solid #c4cde3;
      border-radius: 16px;
      font-family: Conv_IBMPlexSans-Regular;
      font-size: 12px;
      color: #0f202c;
      letter-spacing: .22px;
      text-align: left;
      top: 3px;
    }
    .users-contract-list-con{
      font-family: $ibmplexRegular;
      .users-contract-list-con-header{
        padding: 10px 20px;
        color: #8c9ba5;
        background: #f6f6f6;
        border: 1px solid #eaeaea;
        font-size: 12px;
      }
      .no-records-found{
        margin: 0px -14px;
      }
      .users-contract-list{
        background: #fff;
        font-size: 14px;
        padding: 10px 20px;
        border-left: 1px solid #eaeaea;
        border-right: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
        a{
          margin-right: 10px;
          display: none;
        }
        &:hover{
          a{
            display: inline-block;
          }
        }
      }
    }
    .no-records-found{
      margin-left: 60px;
      margin-right: 25px;
    }
    #user-search-form{
      float: right;
      width: 45%;
      margin-bottom: 10px;
      margin-top: -55px;
      .dropdown{
        display: inline;
        float: right;
        margin-right: 10px;
        color: #3f51b5;
      }

      .btn{
        float: right;
        font-family: $ibmplexLight;
        padding: 6px 20px;
        border: 1px solid #3f51b5;
      }
      .profile-search{
        border: 1px solid #dfe3e6;
        padding: 0 10px;
        background: #FFFFFF;
        border-radius: 5px;
        float: right;
        input[type="text"]{
          font-family: $ibmplexLight;
        }
        img{
          width: 18px;
        }
      }

      .profile-filter{
        width: 40%;
        display: inline-block;
        margin-left: 5%;
      }
    }
    .edit-profile-icon{
      float: right;
      width: 15px;
      position: relative;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
    .contract-side-bar{
      margin-top: -53px;
    }
    h4{
      margin-bottom: 15px;
      margin-top: 10px;
      @include elementStyles(16px, #bd10e0, normal, normal, $ibmplexRegular, null, null);
    }
    .user-profile{
      margin-bottom: 20px;
      margin-left: 60px;
      margin-right: 5px;
      border-radius: 3px;


      form{
        background: #fff;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
      }
      .user-detail{
        padding:25px 0;
        margin: 0 0px 15px;

        .form-group{
          margin-bottom: 30px;
        }
        .form-group-edit{
          margin-bottom: 10px;
        }
        label{
          display: block;
        }
        input[type="text"], input[type="password"], textarea{
          border-bottom: 1px solid #8e8e8e;
          width: 100%;
          color: #000;
        }
        .update-img-block{
          width: 150px;
          position: relative;

          img{
            width: 150px;
            margin-bottom: 15px;
            &.profile-pic{
              width: 130px;
              margin-bottom: 5px;
              margin-left: 15px;
              height:130px;
              border-radius: 50%;
            }
            &.edit-img{
              position: absolute;
              right:4px;
              top:4px;
              width:14px;
              background: rgba(255,255,255,0.4);
              border-radius: 2px;
              padding: 3px;
            }
          }

          h4{
            color:#3f51b5;
            font-size: 12px;
            text-align: center;
            position: relative;
            cursor:pointer;
            input[type="file"]{
              position: absolute;
              width: 100px;
              opacity: 0;
              transform: translateX(-50%);
              cursor:pointer;
            }
          }

        }


      }
      .submit-block{
        padding: 15px;
        background: #f0f2f3;
        input[type="submit"]{

        }
        a{
        cursor: pointer;
        font-family:$ibmplexRegular;
        font-size: 12px;
        color: #2f8cff;
        margin-left:15px;
        &.deactivate-link{
          padding:7px;
          color: #fe6465;
        }
        &.resend-link{
          padding:7px;

        }
        }

      }

    }
    .add-edit-user-contract{
      margin-left: 10px;
      input[type="text"]{
        border-bottom: 1px solid #8e8e8e;
        width: 90%;
      }
      .ml-modal-upload{
        margin-top: 5px;
        padding: 40px 20px 40px 20px;
        border: 1px solid #000;
        width: 240px;
        border-style: dashed;
        h5{
          color: #d3d3d3;
          font-size: 12px;
          font-family: $ibmplexRegular;
        }
        img{
          margin-bottom: 5px;
        }
      }
    }
    .edit-sidebar-footer{
      padding: 15px 22px;
      background: #f0f2f3;
      position: absolute;
      bottom: 0;
      left:0;
      right:0;
      width: 100%;
      transition: all .5s cubic-bezier(.075,.82,.165,1);
      .add-eng-to-forms{
        input[type="checkbox"]{
          width: 20px!important;
          margin: 0px 10px 5px!important;
        }
        span{
          font-size: 13px;
        }
      }
      ul{
        li{
          margin-right: 20px;
          button,a{
            font-size: 14px;
            font-family: $ibmplexRegular;
            color: $linkBlue;
            cursor: pointer;
            &:hover{
              color: $linkBlue;
            }
          }
          button[type="button"]{
            @extend %primary-botton;
          }
          .import-picture{
            color: $linkBlue;
            background: transparent;
            &:disabled{
              cursor: not-allowed;
            }
            img{

              width: 12px;
              margin-right: 3px;
              position: relative;
              top: -2px;
            }
          }
          .delete-picture{
            color: $red;
            background: transparent;
            &:disabled{
              cursor: not-allowed;
            }
            &:hover{
              color: $red;
            }
            img{
              height: 14px;
              margin-right: 3px;
              object-fit: contain;
              position: relative;
              top: -1px;
            }
          }
        }
      }
    }
    .edit-view-tech-spec{

      &.profile-tab{
        margin: 15px 0;
        .add-edit-user-contract{
          margin-left: 10px;
          input[type="text"]{
            border-bottom: 1px solid #8e8e8e;
            width: 90%;
          }
          &.text-edit-area{
          textarea{
            border: 1px solid #8e8e8e;
            width: 91%;
            padding:2px 5px;
            height: 70px;
            margin-top: 8px;
            background: transparent;
          }
        }
        }
        .edit-sidebar-footer{
          padding: 15px 22px;
          background: #f0f2f3;
          position: absolute;
    			bottom: 0;
    			left:0;
    			right:0;
          width: 100%;
          transition: all .5s cubic-bezier(.075,.82,.165,1);
          .add-eng-to-forms{
            input[type="checkbox"]{
              width: 20px!important;
              margin: 0px 10px 5px!important;
            }
            span{
              font-size: 13px;
            }
          }
          ul{
            li{
              margin-right: 20px;
              button,a{
                font-size: 14px;
                font-family: $ibmplexRegular;
                color: $linkBlue;
    						cursor: pointer;
    						&:hover{
    							color: $linkBlue;
    						}
              }
              button[type="button"]{
                @extend %primary-botton;
              }
              .import-picture{
                color: $linkBlue;
                background: transparent;
                &:disabled{
                  cursor: not-allowed;
                }
                img{

    							width: 12px;
    							margin-right: 3px;
    							position: relative;
    							top: -2px;
                }
              }
              .delete-picture{
                color: $red;
                background: transparent;
                &:disabled{
                  cursor: not-allowed;
    						}
    						&:hover{
    							color: $red;
    						}
                img{
    							height: 14px;
    							margin-right: 3px;
    							object-fit: contain;
    							position: relative;
    							top: -1px;
                }
              }
            }
          }
        }
        &.inspection-form-margin{
          margin: 0px 15px;
        }
        .nav-tabs{
          li{
            display: inline-block;
          }
        }
        .select-all{
          cursor: pointer;
          margin-left: 10px;
          display: inline-block;
          color: #8e8e8e;
          input[type = 'checkbox']{
            margin-right: 5px;
          }
        }
        .notice-info{
          font-size: 11px;
          margin-left: 25px;
          margin-bottom: 10px;
          background: #fff1b6;
          padding: 2px 5px;
          color: #a78701;
          border: 1px solid #a78701;
          border-radius: 4px;
        }
        button{
          display: inline-block;
        }
        .lessor-list{
          border: 1px solid #ddd;
          background: $color_fff;
          margin-top: 20px;
          li{
            padding: 10px;
            border-bottom: 1px solid #ddd;
            input[type = 'checkbox']{
              margin-right: 10px;
              height: 12px;
            }
            &:last-child{
              border-bottom:0;
            }
            .fa{
              float: right;
              color: #d3d3d3;
              display: none;
              cursor: pointer;
            }
            .delete-link{
              float: right;
              color: #fe6465;
              cursor: pointer;
              font-size: 12px;
              padding: 8px;
              display: none;
              &.delete-group{
                padding: 3px;
              }
            }

            &:hover{
              .fa{
                display: block;
              }
              .delete-link{
                display: block;
              }
            }
          }
        }

      }
    }

    .lessor-list{
      img{
        height: 35px;
        object-fit: contain;
      }
      h4{
        font-family: $ibmplexRegular;
        font-size: 14px;
      }
    }
  }
  .user-project-ratings{
    .modal-content{
      width: 600px;
      height: 400px;
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
      .modal-body{
        padding: 30px 20px;
        .row-heading{
          border-bottom: 1px solid rgba(0,0,0,0.5);
        }
        h6{
          font-family: $ibmplexRegular;
          font-size: 14px;
          color: #bd10e0;
          margin: 5px 0;
        }
        p{
          font-family: $ibmplexRegular;
          font-size: 12px;
          color: #000000;
          margin: 5px 0;
        }
      }
    }
  }
